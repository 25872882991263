import moment from "moment"
import { columnFormat } from "./constants"
import { Tooltip, styled, tooltipClasses } from "@mui/material"

export const getDateRangeArray = (dateRange, type) => {
    let fromDate = moment().subtract(dateRange, 'days')
    // let toDate = moment(endDate)
    // let diff = toDate.diff(fromDate, type)
    let range = []
    for (let i = 0; i <= dateRange; i++) {
        range.push(moment(fromDate).add(i, type).format(columnFormat))
    }
    return range
}

export const getStatusFromData = (array) => {
    const statusList = array?.map(item => item?.current_state);
    return statusList
}

export const StatusTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: "#f7f7fa",
        "&::before": {
            border: "1px solid #dad9d9"
          },
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#f7f7fa",
        color: "#34383c",
        border: "1px solid #dad9d9",
        // boxShadow: theme.shadows[1],
        fontSize: 14,
        padding: "10px"
    },
}));