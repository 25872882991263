import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

const TooltipContent = ({ statusArray }) => {
    return (
        <div>
            {
                statusArray?.map(item => {
                    return <div key={item?.id + item?.state_changed_utc_time} className='status-item'>
                        <FontAwesomeIcon icon={faCircle}
                            className={`ms-icons ${['SUCCESS', 'UP'].includes(item?.previous_state) ? 'success' : 'failure'}`}
                        />
                        {/* &#8594; */}
                        &#x2794;

                        <FontAwesomeIcon icon={faCircle}
                            className={`ms-icons ${['SUCCESS', 'UP'].includes(item?.current_state) ? 'success' : 'failure'}`}
                        />

                        <span>{moment.utc(item?.state_changed_utc_time).local().tz(moment.tz.guess()).format('MM/DD/YYYY HH:mm:ss z')}</span>
                    </div>
                })
            }
        </div>
    )
}

export default TooltipContent