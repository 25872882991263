import React from 'react'
import Header from './Header'
import DataTable from './DataTable'
import IconDesc from './IconDesc'

const Container = () => {
    return (
        <>
            <Header />

            <DataTable />

            <IconDesc />
        </>
    )
}

export default Container