import { Amplify } from 'aws-amplify';

const awsConfig = {
    API: {
        endpoints: [
            {
                name: "PingDomStatusDataURL",
                endpoint: process.env.REACT_APP_PingDomStatus_Data_Endpoint
            },
        ]
    },
    Auth: {
        region: process.env.REACT_APP_Auth_region,
        userPoolWebClientId: process.env.REACT_APP_Auth_userPoolWebClientId,
        userPoolId: process.env.REACT_APP_Auth_userPoolId,
        mandatorySignIn: true,
        "oauth": {
            "domain": process.env.REACT_APP_Auth_oauth_domain,
            "scope": [
                "email",
                "openid",
                "profile",
                "aws.cognito.signin.user.admin"
            ],
            "redirectSignIn": process.env.REACT_APP_Auth_redirectSignIn,
            "redirectSignOut": process.env.REACT_APP_Auth_redirectSignOut,
            "responseType": process.env.REACT_APP_Auth_responseType,
            "identity_id": process.env.REACT_APP_Auth_oauth_identity_id
        }
    }
};

export function configureAmplify() {

    Amplify.configure(awsConfig)

}