import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons'

export const domain = "quidelortho.com";

export const filterDateRange = 7;

export const timeString = "T00:00:00"

export const columnFormat = 'MMM DD ddd'

export const iconDecider = {
    "SUCCESS" : faCircleCheck,
    "UP" : faCircleCheck,
    "FAILING": faCircleXmark,
    "DOWN": faCircleXmark
}