import { useEffect, useRef, useState } from 'react';
import Container from './components/Container';
import { Auth, Hub } from 'aws-amplify';
import { domain } from './constants';

import './css/global.css'

import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'Grtsk QuidelOrtho V',
    fontSize: 16,
  }
});

function App() {

  const [isNotQuideOrtho, setIsNotQuideOrtho] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);

  const loginBtn = useRef();

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "customOAuthState":
          window.location.href = data;
          break;
        default:
          return;
      }
    });

    Auth.currentAuthenticatedUser()
      .then(user => {
        // const userDomain = user?.attributes?.email?.split("@")?.pop();
        const userType = user?.attributes['custom:UserType'];

        console.log("user", userType)

        setIsAuthorized(true);

        // if ( ![domain].includes(userDomain) ) {
        //   setIsNotQuideOrtho(true);
        // }

        if (userType !== 'E') {
          setIsNotQuideOrtho(true);
        }

      })
      .catch((e) => {
        console.log("Error in Authentication");
        console.error(e);
        loginBtn.current.click();
      });

    return unsubscribe;
  }, [])

  return (
    <ThemeProvider theme={theme}>
    <div className='app-cnt'>

      {
        isNotQuideOrtho ?
          <h1>Does not belong to QuideOrtho. Please check you login domain.</h1>
          :
          isAuthorized ?
            <Container />
            :
            <div>
              <div><h2>Logging in. Please wait...</h2></div>
              <button
                ref={loginBtn}
                style={{ display: 'none' }}
                onClick={() =>
                  Auth.federatedSignIn({ "provider": process.env.REACT_APP_Auth_FederatedSignIn_Provider, "customState": window.location.href })
                    .then(result => {
                      console.log("Authentication federatedSignIn, data- " + JSON.stringify(result));
                    })
                    .catch(err => {
                      console.error("Authentication federatedSignIn, error- " + err);
                    })
                }
              >
                Login
              </button>
            </div>
      }

    </div>
    </ThemeProvider>
  );
}

export default App;
