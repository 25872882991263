import { Alert, Box, CircularProgress, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { API, Auth } from 'aws-amplify';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { columnFormat, filterDateRange, timeString } from '../constants';
import { StatusTooltip, getDateRangeArray, getStatusFromData } from '../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import TooltipContent from './TooltipContent';

const DataTable = () => {

    const [loading, setLoading] = useState(false);
    const [openMsg, setOpenMsg] = useState(false);
    const [message, setMessage] = useState('');

    const [pingdomData, setPingdomData] = useState(null);

    useEffect(() => {
        getData()
    }, [])

    console.log("date range", getDateRangeArray(filterDateRange, 'days'), moment().subtract(filterDateRange, 'days'))

    const getData = async() => {

        setLoading(true);

        const filter = {
            "fromdate": moment().subtract(filterDateRange, 'days').format('YYYY-MM-DD') + timeString,
            "todate": moment().utc().format()
        }

        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();

        API
            .post('PingDomStatusDataURL', '', {
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${token}`
                },
                body: filter
            }).then(res => {

                console.log("ping dom res", res);

                //grouping the data based on the application name
                // const groupedData = groupBy(res["pingdommonitoring-d-websitestatus"], "check_name")

                const sortedRes = Object.entries(res)?.sort((a, b) => a[0]?.localeCompare(b[0]));
                console.log("sortedRes",sortedRes)
                const pingData = sortedRes?.map(data => {
                    return {
                        appName: data[0],
                        statusData: groupByDate(data[1], "state_changed_utc_time") //grouping accr to date and sorting as latest first
                    }
                })

                console.log("pingData", pingData)
                setPingdomData(pingData);

                setLoading(false);

            }).catch(err => {
                console.log("Err", err);
                setLoading(false);
                setOpenMsg(true);
                setMessage("There is an error while getting data");
            })

    }

    // const groupBy = (arr, key) => {
    //     return arr.reduce((acc, cur) => {
    //         if (cur[key]) {
    //             acc[cur[key]] = [...acc[cur[key]] || [], cur];
    //         }
    //         return acc;

    //     }, {});
    // }
    const groupByDate = (arr, key) => {

        // modifying the array accr to local timezone 
        const localTimeZoneArray = arr?.map( item => {
            return{
                ...item,
                [key]: moment.utc(item[key]).local().tz(moment.tz.guess())
            }
        })

        const groupedData = localTimeZoneArray?.reduce((acc, cur) => {
            if (cur[key]) {
                acc[moment(cur[key]).format(columnFormat)] = [...acc[moment(cur[key]).format(columnFormat)] || [], cur];
            }
            return acc;

        }, {});


        const sortedData = Object.keys(groupedData)
            .sort((a, b) => moment(a, columnFormat).diff(moment(b, columnFormat))) //sorting the grouped data accr to date (ascending)
            .reduce((accumulator, currentValue) => { //reordering the nested array so that we get latest data first
                accumulator[currentValue] = groupedData[currentValue]?.sort((a, b) => moment(b?.state_changed_utc_time).diff(moment(a?.state_changed_utc_time)));
                return accumulator;
            }, {})

        //ingesting data for those days on which status change didnt happen based on the previous day's data
        getDateRangeArray(filterDateRange, 'days')?.forEach(date => {
            if (!sortedData[date]) {
                const prevDate = moment(date, columnFormat).subtract(1, "d").format(columnFormat);

                if (sortedData[prevDate]) { //check data for the begining day is present or not
                    sortedData[date] = [
                        {
                            current_state: sortedData[prevDate][0]?.current_state, //ingesting previous day's data
                            previous_state: sortedData[prevDate][0]?.previous_state,
                            state_changed_utc_time: sortedData[prevDate][0]?.state_changed_utc_time
                        }
                    ]
                } else {
                    const beginDate = Object.keys(sortedData)?.length > 0 && Object.keys(sortedData)[0]
                    sortedData[date] = [
                        {
                            current_state: sortedData[beginDate][0]?.current_state,
                            previous_state: sortedData[beginDate][0]?.previous_state,
                            state_changed_utc_time: sortedData[beginDate][0]?.state_changed_utc_time
                        }
                    ]
                }

            }
        })
        console.log("sortedData", sortedData)

        return sortedData;
    }

    const handleMsgClose = () => {
        setOpenMsg(false);

    }


    return (
        <div>

            <Snackbar open={openMsg} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={5000} onClose={handleMsgClose} >
                <Alert onClose={handleMsgClose} severity="error" sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>

            {
                loading ?
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                    :
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className='column-heading ms-bolder sticky-col' >Application Name</TableCell>

                                    {
                                        getDateRangeArray(filterDateRange, 'days')?.map(date => {
                                            const weekDayNumber = moment(date, columnFormat).weekday();
                                            return <TableCell key={date} className={`column-heading ms-bolder ${[0,6].includes(weekDayNumber) ? 'bg-colored' : ''}`} align="center">{date}</TableCell>
                                        })
                                    }


                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {pingdomData?.map((row) => (
                                    <TableRow
                                        key={row.appName}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" className='ms-bolder sticky-col' scope="row">
                                            {row.appName}
                                        </TableCell>

                                        {
                                            getDateRangeArray(filterDateRange, 'days')?.map(date => {
                                                
                                                const weekDayNumber = moment(date, columnFormat).weekday();
                                                if (row?.statusData[date]) {
                                                    if (
                                                        (
                                                            row?.statusData[date][0]?.current_state?.toUpperCase() === "SUCCESS" ||
                                                            row?.statusData[date][0]?.current_state?.toUpperCase() === "UP"
                                                        )
                                                        &&
                                                        (
                                                            getStatusFromData(row?.statusData[date]).includes("FAILING") ||
                                                            getStatusFromData(row?.statusData[date]).includes("DOWN")
                                                        )
                                                    ) {
                                                        return <TableCell key={date} component="td" className={[0,6].includes(weekDayNumber) ? 'bg-colored' : ''} align="center" scope="row">
                                                            <StatusTooltip
                                                                title={<TooltipContent
                                                                    statusArray={row?.statusData[date]}
                                                                />}
                                                                arrow
                                                                placement="top"
                                                            >
                                                                <FontAwesomeIcon icon={faCircle}
                                                                    className='ms-icons info'
                                                                />
                                                            </StatusTooltip>
                                                        </TableCell>
                                                    } else if (
                                                        row?.statusData[date][0]?.current_state?.toUpperCase() === "SUCCESS" ||
                                                        row?.statusData[date][0]?.current_state?.toUpperCase() === "UP"
                                                    ) {
                                                        return <TableCell key={date} component="td" className={[0,6].includes(weekDayNumber) ? 'bg-colored' : ''} align="center" scope="row">

                                                            <StatusTooltip
                                                                title={<TooltipContent
                                                                    statusArray={row?.statusData[date]}
                                                                />}
                                                                arrow
                                                                placement="top"
                                                            >
                                                              <FontAwesomeIcon icon={faCircle}
                                                                    className='ms-icons success'
                                                                />
                                                            </StatusTooltip>

                                                        </TableCell>
                                                    } else {
                                                        return <TableCell key={date} component="td" className={[0,6].includes(weekDayNumber) ? 'bg-colored' : ''} align="center" scope="row">

                                                            <StatusTooltip
                                                                title={<TooltipContent
                                                                    statusArray={row?.statusData[date]}
                                                                />}
                                                                arrow
                                                                placement="top"
                                                            >
                                                                <FontAwesomeIcon icon={faCircle}
                                                                    className='ms-icons failure'
                                                                />
                                                            </StatusTooltip>
                                                        </TableCell>
                                                    }

                                                } else {
                                                    return <TableCell key={date} component="td" className={[0,6].includes(weekDayNumber) ? 'bg-colored' : ''} align="right" scope="row">

                                                    </TableCell>
                                                }
                                            })

                                        }
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

            }
        </div>
    )
}

export default DataTable