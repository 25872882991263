import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'

const IconDesc = () => {
    return (
        <div className='icon-desc-cnt'>
            <div className='icon-desc'>
                <FontAwesomeIcon icon={faCircle}
                    className='ms-icons success'
                    style={{marginRight: "8px"}}
                />
                Service is operating normally
            </div>
            <div className='icon-desc'>
                <FontAwesomeIcon icon={faCircle}
                    className='ms-icons failure'
                    style={{marginRight: "8px"}}
                />
                Service outage
            </div>
            <div className='icon-desc'>
                <FontAwesomeIcon icon={faCircle}
                    className='ms-icons info'
                    style={{marginRight: "8px"}}
                />
                Service disruption
            </div>
        </div>
    )
}

export default IconDesc