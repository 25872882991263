import React, { useEffect, useState } from 'react'
import moment from 'moment';
import momentTimeZone from 'moment-timezone';
import { Auth } from 'aws-amplify';

const Clock = () => {
  const [currentTime, setCurrentTime] = useState(momentTimeZone().tz(moment.tz.guess()).format('MM/DD/YYYY hh:mm:ss A z'));

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(momentTimeZone().tz(moment.tz.guess()).format('MM/DD/YYYY hh:mm:ss A z'))
    }, 1000)

    return () => clearInterval(timer)
  })

  const handleLogout = async () => {
    await Auth.signOut();
  }

  return (
    <div>
      <p className='timer-font'>{currentTime}</p>
      <p className='timer-msg-font textRight'>The shown time zone is same as yours</p>
      <p className='link textRight' onClick={handleLogout}>Logout</p>
    </div>
  )
}

export default Clock