import React, { useEffect, useState } from 'react'
import QO_Logo from '../assets/QO_Logo.svg'
import Clock from './Clock'
import MenuClock from './MenuClock'

const Header = () => {

  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {

    const handleResize = () => {
      setScreenSize(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);

  })


  return (
    <div className='header-cnt'>
      <img
        src={QO_Logo}
        alt="QuidelOrtho"
        className='qo-logo'
      />

      {
        screenSize > 767 ?
          <Clock />
          :
          <MenuClock />
      }

    </div>
  )
}

export default Header