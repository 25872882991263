import { Menu } from '@mui/material'
import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import Clock from './Clock'


const MenuClock = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div>

            <FontAwesomeIcon icon={faCaretDown} className='ms-icons qo-icon'
                onClick={handleClick}
            />
            <Menu
                id="clock-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}

            >
                <div className='clock-menu'>
                    <Clock />
                </div>

            </Menu>
        </div>
    )
}

export default MenuClock